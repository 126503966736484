<template>
  <div>
    <div class="container">
      <div class="d-flex flex-wrap align-items-center pt-3 pb-2 mb-3">
        <h3>{{ $t("nav.user_info") }}</h3>
      </div>
      <div class="personal-content">
        <el-form
          :model="formData"
          :rules="rules"
          ref="personalForm"
          size="small"
        >
          <el-form-item :label="$t('person.email') + '：'" prop="email">
            <el-input v-model="formData.email"></el-input>
          </el-form-item>
          <el-form-item :label="$t('person.username') + '：'" prop="username">
            <el-input v-model="formData.username"></el-input>
          </el-form-item>
          <el-form-item :label="$t('person.name') + '：'" prop="realname">
            <el-input v-model="formData.realname"></el-input>
          </el-form-item>
          <el-form-item :label="$t('person.org') + '：'">
            <el-input
              v-model="formData.address"
              :disabled="user.role !== '0'"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="user.role !== '0'"
            :label="$t('person.department') + '：'"
          >
            <el-input v-model="formData.office" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item :label="$t('person.title') + '：'">
            <el-input v-model="formData.title"></el-input>
          </el-form-item>
          <el-form-item :label="$t('person.telephone') + '：'">
            <el-input v-model="formData.telephone"></el-input>
          </el-form-item>
        </el-form>
        <div>
          <button
            type="button"
            class="btn btn-primary btn-sm"
            @click.stop="submitForm('personalForm')"
          >
            {{ $t("save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from "../../api/api";
import { Loading } from "element-ui";

export default {
  data() {
    return {
      formData: {
        id: "",
        email: "",
        username: "",
        realname: "",
        address: "",
        office: "",
        title: "",
      },
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : "",
    };
  },
  computed: {
    rules() {
      return {
        email: [
          {
            required: true,
            message: this.$t("validate.email"),
            trigger: "blur",
          },
        ],
        username: [
          {
            required: true,
            message: this.$t("validate.username"),
            trigger: "blur",
          },
        ],
        realname: [
          {
            required: true,
            message: this.$t("validate.name"),
            trigger: "blur",
          },
        ],
      };
    },
  },
  mounted() {
    this.formData = {
      id: this.user.id || "",
      email: this.user.email || "",
      username: this.user.username || "",
      realname: this.user.realname || "",
      address: this.user.address || "",
      office: this.user.office || "",
      title: this.user.title || "",
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.handeleChangeInfo();
        } else {
          return false;
        }
      });
    },
    async handeleChangeInfo() {
      let _self = this;
      let submitData = {
        type: "profile",
        ...this.formData,
      };
      try {
        let res = await login(submitData);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          // this.$helper.successNotify(res.message, function() {
          //   localStorage.removeItem("token");
          //   localStorage.removeItem("user");
          //   _self.$router.push("/")
          // }, 1000);
          let user = Object.assign({}, this.user, res.data);
          this.$store.state.username = user.username;
          localStorage.user = JSON.stringify(user);
        } else {
          this.$helper.errNotify(res.message);
        }
      } catch (error) {
        console.log(error.message);
        this.$helper.errNotify(error.message);
      }
    },
  },
};
</script>

<style lang="less">
.personal-content {
  width: 700px;
  label {
    height: 24px;
    line-height: 24px !important;
  }
}
</style>